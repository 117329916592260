import { Outlet } from '@remix-run/react';
import { useLayoutEffect, useMemo, useState } from 'react';

import { CrossOriginStorageClient } from '../../components/CrossOriginStorage';
import { GlobalLoading } from '../../components/GlobalLoading';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import logger from '../../logger/logger';
import { err2s } from '../../utils/common';
import { type IStorage, StorageFactory } from '../../utils/storage';

const KEY = 'loginStateSyncV2';

type LoginStateSyncResult = {
  state: 'success' | 'failed';
  syncedAt: number;
  hasToken: boolean;
};

function LoginStateSyncerInternal(props: {
  origin: {
    hub: string;
    client: string;
  };
  timeoutSec?: number;
  storage: IStorage<typeof KEY, LoginStateSyncResult>;
}): JSX.Element {
  const { origin, timeoutSec = 10, storage } = props;
  const [done, setDone] = useState(false);
  const log = useInstance(() => logger.scoped('cross-storage-client'));
  const client = useMemo(
    () => new CrossOriginStorageClient(`${origin.hub}/storage-hub`, log),
    [origin.hub, log]
  );

  useLayoutEffect(() => {
    logger.verbose(getFeatureQueryParam('verbose-local-logging'));
    client.up().then(() => {
      client
        .get('token', timeoutSec * 1000)
        .then((token) => {
          log.info('synced state', { state: 'success', hasToken: !!token });
          storage.set(KEY, {
            state: 'success',
            syncedAt: Date.now(),
            hasToken: !!token,
          });
          if (!token) return;
          localStorage.setItem('token', token);
        })
        .catch((err) => {
          log.info('synced state', {
            state: 'failed',
            hasToken: false,
            error: err2s(err),
          });
          storage.set(KEY, {
            state: 'failed',
            syncedAt: Date.now(),
            hasToken: false,
          });
        })
        .finally(() => {
          setDone(true);
        });
    });
    return () => {
      client.down();
    };
  }, [client, log, storage, timeoutSec]);

  return done ? <Outlet /> : <GlobalLoading />;
}

export function LoginStateSyncer(props: {
  enabled: boolean;
  origin: {
    hub: string;
    client: string;
  };
  timeoutSec?: number;
  children?: JSX.Element;
}): JSX.Element {
  const storage = useInstance(() =>
    StorageFactory<typeof KEY, LoginStateSyncResult>('local')
  );
  let token: string | null = null;
  let synced: LoginStateSyncResult | null = null;
  try {
    token = localStorage.getItem('token');
    synced = storage.get(KEY);
  } catch (error) {
    console.error(error);
  }
  if (
    !props.enabled ||
    !!token ||
    !!synced ||
    window.location.origin !== props.origin.client
  ) {
    return props.children ? props.children : <Outlet />;
  }
  return <LoginStateSyncerInternal {...props} storage={storage} />;
}
