import { assertExhaustive } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { err2s, raceWithTimeout, uuidv4 } from '../../utils/common';

const STORAGE = 'cors-storage';

type Request = {
  type: typeof STORAGE;
  id: string;
  payload: {
    action: 'get';
    key: string;
  };
};

type Response = {
  type: typeof STORAGE;
  id: string;
  data: {
    action: 'get';
    key: string;
    val: string | null;
  };
};

class Utils {
  static IsValidRequest(data: unknown): data is Request {
    if (
      !data ||
      typeof data !== 'object' ||
      !('type' in data) ||
      !('id' in data) ||
      !('payload' in data)
    )
      return false;
    return Boolean(data['type'] === STORAGE && data['id'] && data['payload']);
  }

  static IsValidResponse(data: unknown): data is Response {
    if (
      !data ||
      typeof data !== 'object' ||
      !('type' in data) ||
      !('id' in data) ||
      !('data' in data)
    )
      return false;
    return Boolean(data['type'] === STORAGE && data['id'] && data['data']);
  }
}

export class CrossOriginStorageClient {
  private frame;
  private origin;
  constructor(hubURL: string, readonly log: Logger) {
    this.frame = this.createFrame(hubURL);
    this.origin = new URL(hubURL).origin;
  }

  async up(el?: HTMLElement) {
    const target = el || window.document.body;
    this.log.info('frame load start');
    const p = new Promise<void>((resolve) => {
      this.frame.addEventListener('load', () => {
        resolve();
      });
    });
    target.appendChild(this.frame);
    await p;
    this.log.info('frame load done');
  }

  down() {
    this.frame.parentNode?.removeChild(this.frame);
  }

  private createFrame(url: string) {
    const frame = window.document.createElement('iframe');
    frame.style.display = 'none';
    frame.style.position = 'absolute';
    frame.style.top = '-9999px';
    frame.style.left = '-9999px';
    frame.src = url;
    return frame;
  }

  private parseResponse(
    requestId: string,
    event: MessageEvent
  ): Response | undefined {
    if (event.origin !== this.origin) {
      this.log.debug('received message from unknown origin', {
        origin: event.origin,
      });
      return;
    }
    if (!Utils.IsValidResponse(event.data)) {
      this.log.debug('received unknown message', {
        data: event.data,
      });
      return;
    }
    if (event.data.id !== requestId) {
      this.log.warn('received message with different id', {
        data: event.data,
      });
      return;
    }
    return event.data;
  }

  private send(request: Request) {
    this.log.info('sending message', { request, origin: this.origin });
    this.frame.contentWindow?.postMessage(request, this.origin);
  }

  async get(
    key: string,
    timeoutMs = 1000,
    intervalMs = 100
  ): Promise<string | null> {
    const aborter = new AbortController();
    const ctrl = new BrowserIntervalCtrl();
    try {
      return await raceWithTimeout(
        new Promise<string | null>((resolve) => {
          const requestId = uuidv4();
          window.addEventListener(
            'message',
            (event) => {
              const response = this.parseResponse(requestId, event);
              if (!response) return;
              ctrl.clear();
              resolve(response.data.val);
            },
            {
              signal: aborter.signal,
            }
          );
          // the hub may be ready after the client is up (frame loaded)
          // so keep sending the same request until the hub replies
          ctrl.set(() => {
            this.send({
              type: STORAGE,
              id: requestId,
              payload: {
                action: 'get',
                key,
              },
            });
          }, intervalMs);
        }),
        timeoutMs
      );
    } catch (error) {
      throw error;
    } finally {
      ctrl.clear();
      aborter.abort();
    }
  }
}

export class CrossOriginStorageHub {
  private origins;
  constructor(origins: string[], readonly log: Logger) {
    this.origins = new Set(origins);
  }

  private parseRequest(event: MessageEvent): Request | undefined {
    if (!this.origins.has(event.origin)) {
      this.log.debug('received message from unknown origin', {
        origin: event.origin,
      });
      return;
    }
    if (!Utils.IsValidRequest(event.data)) {
      this.log.debug('received unknown message', {
        data: event.data,
      });
      return;
    }
    return event.data;
  }

  private reply(response: Response, origin: string) {
    window.parent.postMessage(response, origin);
  }

  on() {
    this.log.info('listening for messages');
    const aborter = new AbortController();
    window.addEventListener(
      'message',
      (event) => {
        const request = this.parseRequest(event);
        if (!request) return;
        this.log.info('received message', { data: event.data });
        switch (request.payload.action) {
          case 'get':
            let val: string | null = null;
            try {
              val = localStorage.getItem(request.payload.key);
              this.log.info('val', {
                key: request.payload.key,
                val,
              });
            } catch (error) {
              this.log.error('fail to access localStorage', err2s(error), {
                key: request.payload.key,
              });
            }
            this.reply(
              {
                type: STORAGE,
                id: request.id,
                data: {
                  action: 'get',
                  key: request.payload.key,
                  val,
                },
              },
              event.origin
            );
            break;
          default:
            assertExhaustive(request.payload.action);
            break;
        }
      },
      { signal: aborter.signal }
    );
    return () => {
      aborter.abort();
    };
  }
}
